import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FaRegCalendarAlt, FaUser, FaMap } from 'react-icons/fa';

import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import CoursesList from '../courses/components/CoursesList';
import { Collage } from '../../components/Image';
import { HeroContainer } from '../../components/Containers';

import Cooking from '../courses/Cooking';

import Content, { HTMLContent } from '../../components/Content';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import mediaSizes from '../../components/MediaSizes';

const DayList = styled.ol`
    list-style: none;
    margin-left: 20px !important;

    li {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid grey;
    }

    li:before {
        content: '>';
        padding-right: 8px;
        margin-left: -18px;
    }
`;

const Gallery = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(${props => props.rows}, 10vw);
    grid-gap: 10px;

    @media (max-width: ${mediaSizes.tablet}px) {
        grid-template-rows: repeat(${props => props.rows}, 30vw);
    }
`;

export const CourseTemplate = ({
    title,
    startDate,
    endDate,
    location,
    instructor,
    description,
    featuredcourse,
    soldout,
    cost,
    days,
    courseHighlights,
    featuredimage,
    content,
    contentComponent
}) => {
    const CourseContent = contentComponent || Content;
    const add = [];
    return (
        <React.Fragment>
            <HeroContainer
                title="One Day Cooking Class"
                image="/img/cooking.jpg"
            />
            <section className="section">
                <div className="container">
                    <div className="content">
                        <div className="columns">
                            <div className="column is-12">
                                <h2 className="has-text-primary is-size-2">
                                    One Day Cooking Class
                                </h2>
                                <p>
                                    <FaUser /> With{' '}
                                    <strong>Chef Karolina</strong>
                                </p>
                                <p>
                                    <FaMap /> At Villa Sole
                                </p>
                                <p>
                                    <FaRegCalendarAlt /> Various Dates Available
                                </p>
                                <p>
                                    This one day cooking class is perfect for a
                                    group of friends, family or couples looking
                                    to experience authentic italian food in a
                                    beautiful, relaxed and fun setting. If you
                                    love Italian food and want to discover new
                                    and delicious recipes in stunning Tuscan
                                    surroundings - this is the cooking class for
                                    you! The One Day Cooking Classes in Villa
                                    Selva begins with selecting the freshest of
                                    ingredients from the beautiful garden
                                    followed by a three hour cooking workshop
                                    with our amazing English-speaking chef
                                    Karolina who will walk you through making a
                                    classic Italian dinner with a primo (pasta
                                    dish), a secondo (main course), a contorno
                                    (side dish) and a delectable dolce, (sweet
                                    course). This is followed by a wonderful
                                    meal and selection of local wines tasted
                                    during the lesson. If there is something in
                                    particular you would like to cook, you are
                                    welcome to specify with the chef beforehand.
                                    Classes are taught in a fun and relaxed
                                    atmosphere and you'll each have a go at
                                    making your own pasta and get a real feel
                                    for authentic italian cooking experience.
                                    Please know that we are taking extra
                                    precautions to keep our customers safe, we
                                    only offer private classes to guarantee an
                                    exclusive, unique hands-on lesson.
                                </p>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <PreviewCompatibleImage
                                    className=""
                                    imageInfo={{
                                        image: '../../img/karolina-cooking.jpg',
                                        alt: `featured image thumbnail for Chef Karolina`
                                    }}
                                />
                            </div>
                            <div className="column is-6">
                                <p>
                                    Karolina Lenart (Born in Poland) grew up in
                                    Tuscany where she took her culinary passion
                                    from her mother, who has been associated
                                    with Italy for over 30 years. Karolina
                                    specialises in Tuscan, Italian and
                                    Mediterranean cuisine. She has been living
                                    permanently in Lucca-Tuscany for many years.
                                    She trained in one of the best international
                                    Culinary Academies under the supervision of
                                    Chef Gianluca Pardrini. Karolina gained
                                    experience through travel with the best
                                    chefs from Italy, USA, Canada and Australia
                                    (Angelo Prosperi-Porta, Tony Carter, Mathew
                                    Dillow are just some of the names). She says
                                    about herself: "I am a restless spirit
                                    constantly looking for new flavors"
                                    Ambitious, persistent and perfectionist. A
                                    lover of good food and the highest quality
                                    of the local produce. Chef Karolina cooks in
                                    harmony with the seasons and Italian Slow
                                    Living approach. She loves to share her
                                    passion with people by hosting courses,
                                    special events and intimate dinner parties.
                                </p>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-4">
                                <h2 style={{ color: 'green' }}>
                                    Course Highlights
                                </h2>
                                <ol>
                                    <li type="disc">
                                        English-speaking private chef (Karolina
                                        also speaks Polish and Italian)
                                    </li>
                                    <li type="disc">
                                        Prosecco welcome reception
                                    </li>
                                    <li type="disc">
                                        Villa Sole's Garden tour (to pick your
                                        own herbs and veg)
                                    </li>
                                    <li type="disc">
                                        All ingredients and equipment
                                    </li>
                                    <li type="disc">
                                        5 course meal with wine at the end of
                                        the course (LUNCH or DINER)
                                    </li>
                                    <li type="disc">
                                        Follow Your Senses in Tuscany Apron
                                    </li>
                                    <li type="disc">
                                        All Recipes emailed as PDFs
                                    </li>
                                </ol>
                                <div style={{ textAlign: 'center' }}>
                                    <a
                                        className="btn"
                                        href="mailto:followyoursensesintuscany@gmail.com?subject=Cooking Course"
                                    >
                                        Enquire Now
                                    </a>
                                </div>
                            </div>
                            <div className="column is-8">
                                <PreviewCompatibleImage
                                    className=""
                                    imageInfo={{
                                        image:
                                            '../../img/cooking-lessons-3.jpg',
                                        alt: `featured image thumbnail for Chef Karolina`
                                    }}
                                />
                                {/* <Gallery rows={Math.ceil(add.length / 2)}>
                                    {add.map(additionalImage => (
                                        <PreviewCompatibleImage
                                            className=""
                                            imageInfo={{
                                                image: additionalImage.image,
                                                alt: `featured image thumbnail for ${title}`
                                            }}
                                        />
                                    ))}
                                </Gallery> */}
                            </div>
                        </div>
                        {/* <div className="columns">
                            <div className="column is-12">
                                <h2>Day by Day Workshop Schedule</h2>
                                <DayList type="none">
                                    {days.map(day => (
                                        <li>
                                            Day {day.day}: {day.dayDescription}
                                        </li>
                                    ))}
                                </DayList>
                            </div>
                        </div> */}
                        <div className="columns">
                            <div className="column is-6">
                                <PreviewCompatibleImage
                                    className=""
                                    imageInfo={{
                                        image:
                                            '../../img/cooking-lessons-1.jpg',
                                        alt: `featured image thumbnail for Chef Karolina`
                                    }}
                                />
                            </div>
                            <div className="column is-6">
                                <PreviewCompatibleImage
                                    className=""
                                    imageInfo={{
                                        image:
                                            '../../img/cooking-lessons-2.jpg',
                                        alt: `featured image thumbnail for Chef Karolina`
                                    }}
                                />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-12">
                                <PreviewCompatibleImage
                                    className=""
                                    imageInfo={{
                                        image:
                                            '../../img/cooking-lessons-4.jpg',
                                        alt: `featured image thumbnail for Chef Karolina`
                                    }}
                                />
                            </div>
                        </div>
                        <CourseContent content={content} />
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

const CookingPage = () => (
    <Layout>
        <SEO
            title="Cooking Class - Follow Your Senses In Tuscany"
            image="/img/cooking.jpg"
            description="One Day Cooking Classes"
        />
        <CourseTemplate
            contentComponent={HTMLContent}
            helmet={
                <Helmet titleTemplate="%s | Blog">
                    <title>
                        Cooking Classes - Follow Your Senses In Tuscany
                    </title>
                    <meta
                        name="description"
                        content="One Day Cooking Classes"
                    />
                </Helmet>
            }
        />
    </Layout>
);

export default CookingPage;
